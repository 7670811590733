import { basicSetup, EditorState, EditorView } from "@codemirror/basic-setup";
import { javascript } from "@codemirror/lang-javascript";
// import { oneDark } from "@codemirror/theme-one-dark";
export default class CodeViewer {
  constructor(layer, code, offset = 0, src = null) {
    this.layer = layer;
    // console.log(code);
    // console.log(src);
    // console.log(code.indexOf("StudentGIT"));
    // let element = layer.querySelector(".code-viewer");
    // if (element) {
    //   layer.removeChild(element);
    // }
    let element = document.createElement("div");
    element.className = "code-viewer";
    // element.style.position = "absolute";
    // element.style.top = "30px";
    // element.style.zIndex = "10";
    element.style.backgroundColor = "white";
    element.style.width = "calc(100% - 1px)";
    layer.appendChild(element);
    element.innerHTML = `<h1 style='margin-left:${offset * 111}px'>${code
      .split("/")
      .pop()}</h1>`;
    this.container = element;
    // get host url
    const host = window.location.hostname; //"localhost"
    this.code =
      code.indexOf("StudentGIT") < 0 && code.indexOf(host) < 0
        ? src.split("index.html")[0] + code.split(`${host}/`).pop()
        : code;
    // console.log("***", this.code);
    this.init();
  }

  init() {
    // console.log("CODEVIEWER INIT", this.code);
    fetch(this.code)
      .then((response) => response.text())
      .then((text) => {
        // const state = EditorState.create({
        //   doc: text,
        //   extensions: [basicSetup, javascript(), oneDark],
        // });
        // const view = new EditorView({
        //   state,
        //   parent: this.container,
        // });

        // console.log(text);
        const maxSize = window.innerHeight - 90 - 30 + "px";
        const initialState = EditorState.create({
          doc: text,
          extensions: [basicSetup, javascript()],
        });

        const view = new EditorView({
          parent: this.container,
          state: initialState,
        });
        view.dom.style.overflow = "auto";
        view.dom.style.height = maxSize;
        view.dom.style.maxHeight = maxSize;
      });

    //   add Interaction on the h1
    const h1 = this.container.querySelector("h1");
    h1.addEventListener("click", (e) => {
      // console.log("CLICKED");
      this.resetAllH1();
      h1.classList.add("active");
      //   console.log(h1.nextElementSibling);
      h1.nextElementSibling.style.setProperty("display", "block", "important");
    });
  }

  resetAllH1() {
    this.layer.querySelectorAll("h1").forEach((h1) => {
      h1.style.backgroundColor = "#f5f5f5";
      h1.classList.remove("active");
      h1.nextElementSibling.style.setProperty("display", "none", "important");
    });
  }
}
