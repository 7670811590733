/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

// import Playground from "@onemorestudio/playgroundjs";
import Utils from "./Utils";
import Trombinoscope from "./Trombinoscope";
export default class App {
  constructor() {
    // super();
    // const host = window.location.hostname;
    // alert(host);
    // this.draw();
    this.handlers = {
      resize: this.resize.bind(this),
    };
    this.init();
    window.addEventListener("resize", this.handlers.resize);
  }

  async init() {
    const data = await Utils.loadJSON("./json/projects.json");
    const projects = await Utils.loadJSON("./json/studentsWorks.json");

    this.trombinoscope = new Trombinoscope(
      data,
      projects,
      document.querySelector(".trombinoscope")
    );

    document.getElementsByTagName("h1")[0].addEventListener("click", () => {
      //  reload page
      window.location.reload();
    });
  }

  resize() {
    // this.trombinoscope.createGrid();
  }

  //draw() {
  //  requestAnimationFrame(this.draw.bind(this));
  //}
}
