export default class Gallery {
  constructor(container, data, student, callback) {
    this.container = container;
    this.data = data;
    this.student = student;
    this.callback = callback;
    this.activeIndex = null;

    this.init();
  }

  init() {
    const wrapper = document.createElement("div");
    wrapper.className = "projectWrapper";
    this.data[this.student].forEach((project_url, index) => {
      //   if (index <= 2) {
      const iframeWrapper = document.createElement("div");
      iframeWrapper.className = "iframeWrapper";
      iframeWrapper.id = index;
      const iframe = document.createElement("iframe");
      iframe.setAttribute("data-id", index);
      //   iframe.addEventListener("load", this.callback.bind(this));
      iframe.src = "./" + "loader.html"; // project_url;
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframeWrapper.appendChild(iframe);
      wrapper.appendChild(iframeWrapper);
      //   }
    });
    this.container.appendChild(wrapper);
    this.addListeners();
    this.fixPosition(0);
  }

  addListeners(numberOfProjects) {
    const projectWrapperHeight = window.innerHeight - 60 - 30;

    window.addEventListener("scroll", () => {
      const scrollY = window.scrollY;
      const scrollIndex =
        scrollY / projectWrapperHeight -
          Math.floor(scrollY / projectWrapperHeight) >=
        0.5
          ? Math.ceil(scrollY / projectWrapperHeight)
          : Math.floor(scrollY / projectWrapperHeight);

      //detect when scrolling is finished even if not total height
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fixPosition(scrollIndex);
      }, 100);
    });
  }

  fixPosition(scrollIndex) {
    // console.log("SCROLL INDEX", scrollIndex);
    // this.activeIndex = scrollIndex;
    const element = document.getElementById(scrollIndex);

    if (element) element.scrollIntoView({ behavior: "smooth" }, true);
    if (scrollIndex != this.activeIndex || this.activeIndex == null) {
      this.activeIndex = scrollIndex;
      // load iframe real src
      this.loadIframe(this.activeIndex);
    }
  }

  loadIframe(id) {
    // reset all iframes
    Array.from(
      document.getElementsByClassName("projectWrapper")[0].children
    ).forEach((iframe) => {
      iframe.firstChild.src = "./" + "loader.html";
    });

    const iframe = document.getElementById(id);

    if (!iframe.firstChild.dataset.haslistener) {
      iframe.firstChild.addEventListener("load", this.callback.bind(this));
      iframe.firstChild.setAttribute("data-haslistener", true);
    }
    iframe.firstChild.src = this.data[this.student][id];
  }
}
