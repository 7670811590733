import Gallery from "./Gallery.js";
import CodeViewer from "./CodeViewer.js";
export default class Trombinoscope {
  constructor(data, projects, container) {
    this.data = data;
    this.projects = projects;
    this.container = container;

    this.handlers = {
      click: this.onClick.bind(this),
    };
    this.createGrid();
  }

  createGrid() {
    const cols = Math.floor(Math.sqrt(this.data.students.length));
    const rows = Math.ceil(this.data.students.length / cols);
    const width = (this.container.offsetWidth - cols * 10) / cols;
    const height = (this.container.offsetHeight - rows * 10) / rows;

    this.container.innerHTML = "";
    this.data.students.forEach((item, index) => {
      const wrapper = document.createElement("div");
      wrapper.className = "wrapper";
      wrapper.classList.add("out");
      wrapper.style.width = width + "px";
      wrapper.style.height = height + "px";
      wrapper.setAttribute("data-folder", item.folder);

      const circle = document.createElement("div");

      circle.className = "circle";
      circle.style.width = (height < width ? height : width) + "px";
      circle.style.height = (height < width ? height : width) + "px";

      const img = document.createElement("div");
      img.className = "image";
      img.style.backgroundImage = `url(./images/students/${item.image})`;

      //   img.alt = item.name;
      circle.appendChild(img);
      wrapper.appendChild(circle);
      this.container.appendChild(wrapper);

      wrapper.addEventListener(
        "animationend",
        this.cleanAfterArrival.bind(this),
        { once: true }
      );

      setTimeout(() => {
        wrapper.classList.add("bounceInDown");
      }, (cols * rows - index) * 80);

      // add click
      wrapper.addEventListener("click", this.handlers.click);
    });
  }

  cleanAfterArrival(e) {
    // console.log("clean AFTER ARRIVAL");
    const wrapper = e.target;
    wrapper.classList.remove("out");
    wrapper.classList.remove("bounceInDown");
    wrapper.setAttribute("data-top", wrapper.getBoundingClientRect().top);
    wrapper.setAttribute("data-left", wrapper.getBoundingClientRect().left);
    wrapper.setAttribute("data-width", wrapper.getBoundingClientRect().width);
    wrapper.setAttribute("data-height", wrapper.getBoundingClientRect().height);
    wrapper.removeEventListener(
      "animationend",
      this.cleanAfterArrival.bind(this)
    );
  }

  afterTransition(e) {
    const top = e.target.getBoundingClientRect().top - 3;
    const left = e.target.getBoundingClientRect().left - 3;
    const width = e.target.getBoundingClientRect().width;
    const height = e.target.getBoundingClientRect().height;
    // console.log("HEAD FACE DONE", top, left);
    e.target.style.position = "fixed";
    e.target.style.top = top + "px";
    e.target.style.left = left + "px";
    e.target.style.width = width + "px";
    e.target.style.height = height + "px";
    e.target.firstChild.style.width = height + "px";
    e.target.firstChild.style.height = height + "px";
    e.target.style.zIndex = 10;

    e.target.style.transition = "none";
    e.target.style.transform = "translate3d(0px,0px,0px)";
    setTimeout(() => {
      e.target.style.transition = null;
    }, 10);

    //
    const shield = document.createElement("div");
    shield.className = "shield";
    document.body.appendChild(shield);
    shield.addEventListener("click", (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    });

    const div = document.createElement("div");
    div.classList.add("projets");
    document.body.appendChild(div);
    // div.appendChild(iframe);

    const gallery = new Gallery(
      div,
      this.projects,
      e.target.dataset.folder,
      this.onIframeLoaded.bind(this)
    );
  }

  injectScript(url, doc) {
    console.log(doc);
    // const innerDoc = e.contentDocument || e.contentWindow.document;
    const script = doc.createElement("script");
    script.setAttribute("src", url);
    doc.body.appendChild(script);
    script.addEventListener("load", () => {
      // console.log("P5 LOADED");
    });
  }

  onIframeLoaded(e) {
    console.log("IFRAME LOADED", e);
    // console.log(window.parent.document, e.target.dataset.id);
    const layer = window.parent.document.getElementById(e.target.dataset.id);
    const tab = layer.querySelector(".tabLayer");
    if (tab) {
      layer.removeChild(tab);
    }

    const url_path = e.target.src.split("/");
    const innerDoc =
      e.target.contentDocument || e.target.contentWindow.document;
    const style = innerDoc.createElement("style");
    style.innerHTML = `html{overflow:hidden;}
          .p5Logo {
            position: absolute;
            top:2px;
            left:2px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;

            opacity: 0.5;
          }
         
          .p5Logo img{
              width: 100%;
              height: 100%;
              object-fit: cover;
          }`;
    innerDoc.head.appendChild(style);
    e.target.contentWindow.console.log = () => {};
    this.hasP5 = false;

    // create new tab layer for code
    const tabLayer = document.createElement("div");
    tabLayer.className = "tabLayer";
    layer.appendChild(tabLayer);

    let scriptNumber = 0;
    Array.from(innerDoc.head.getElementsByTagName("script")).forEach(
      (script) => {
        const script_path = script.src.split("/");
        console.log("---", script.src, script_path);

        // if (script.src.indexOf("StudentGIT") < 0) {
        //   console.log("NO STUDENT GIT");
        //   // assuming we are facing a local file
        //   script.src =
        //     e.target.src.split("index.html")[0] +
        //     script.src.replace("http://localhost/", "");
        // }

        if (script.src.match("p5.js") && !script.src.match("./p5.js")) {
          this.injectScript("./p5.js", innerDoc);
          this.hasP5 = true;
        } else if (script.src.match("../p5.js")) {
          this.injectScript("./p5.js", innerDoc);
          this.hasP5 = true;
        } else if (script.src.match("p5")) {
          this.hasP5 = true;
        } else if (!script.src.match("pixi")) {
          console.log("CODE VIEWER FROM HEADER");
          new CodeViewer(tabLayer, script.src, scriptNumber, e.target.src);
          scriptNumber++;
        }
      }
    );

    // FIX FOR ABSOLUTE PATH
    Array.from(innerDoc.body.getElementsByTagName("script")).forEach(
      (script) => {
        const script_path = script.src.split("/");
        console.log(url_path[3], script_path[3], script_path[0]);
        if (url_path[3] != script_path[3] && script_path[0] != "https:") {
          const newPath = script_path.splice(3, script_path.length).join("/");
          this.injectScript(newPath, innerDoc);
          console.log("CODE VIEWER ON THE GO within the body");
          new CodeViewer(tabLayer, newPath, scriptNumber, e.target.src);
          scriptNumber++;
          if (script.src.match("p5")) {
            // reinject p5
            setTimeout(() => {
              this.injectScript(
                "https://cdn.jsdelivr.net/npm/p5@1.4.2/lib/p5.js",
                innerDoc
              );
              innerDoc.body.removeChild(script);
            }, 100);
            this.hasP5 = true;
          }
        } else if (!script.src.match("p5.js") && !script.src.match("pixi")) {
          const newPath = script_path.splice(3, script_path.length).join("/");

          console.log("CODE VIEWER ON THE GO within the body");
          new CodeViewer(tabLayer, newPath, scriptNumber, e.target.src);
          scriptNumber++;
        }
      }
    );

    if (this.hasP5) {
      this.addP5Logo(innerDoc);
    }
    this.addCodeIcon(layer);

    // e.target.contentWindow.dispatchEvent(new Event("load"));
  }

  cleanScreen(e) {
    // console.log("clean SCREEN");
    e.target.removeEventListener("animationend", this.cleanScreen.bind(this));
    e.target.style.position = null;
    e.target.style.top = null;
    e.target.style.left = null;
    e.target.style.zIndex = null;
    e.target.style.width = e.target.dataset.width + "px";
    e.target.style.height = e.target.dataset.height + "px";
    e.target.firstChild.style.width = e.target.dataset.height + "px";
    e.target.firstChild.style.height = e.target.dataset.height + "px";

    e.target.style.transform = null;
    e.target.classList.remove("headFace");

    // remove gallery
    document.body.removeChild(document.querySelector(".projets"));
    // remove shield
    document.body.removeChild(document.querySelector(".shield"));
    this.addGallery();
  }

  onClick(e) {
    // console.log(e.target.dataset.folder);
    // console.log(this.projects);
    // console.log(this.projects[e.target.dataset.folder]);
    // const iframe = document.createElement("iframe");
    // iframe.src = "./" + this.projects[e.target.dataset.folder][0];
    // iframe.style.width = "100%";
    // iframe.style.height = "100%";
    // document.body.appendChild(iframe);
    // console.log(e.target);
    if (e.target.classList.contains("headFace")) {
      e.target.classList.add("bounceOutDown");
      e.target.addEventListener("animationend", this.cleanScreen.bind(this), {
        once: true,
      });
    } else {
      e.target.classList.add("rubberBand");
      e.target.style.zIndex = 6;
      this.removeGallery(e.target);
    }
  }

  removeGallery(element) {
    Array.from(document.getElementsByClassName("wrapper")).forEach(
      (item, index) => {
        if (item != element)
          setTimeout(() => {
            item.classList.add("bounceOutDown");
          }, (Array.from(document.getElementsByClassName("wrapper")).length - index) * 40);
        else
          setTimeout(() => {
            item.classList.remove("rubberBand");
            item.classList.add("headFace");
            setTimeout(() => {
              item.style.transform = `translate3d(${
                -parseInt(item.dataset.left) +
                window.innerWidth -
                item.dataset.width / 2 -
                (item.dataset.height / 2) * 0.2 -
                36
              }px, ${
                -item.dataset.top - 0.2 * item.dataset.height * 2 + 10
              }px, 0px) scale(0.2)`;
              item.addEventListener(
                "transitionend",
                this.afterTransition.bind(this),
                { once: true }
              );
            }, 10);
          }, Array.from(document.getElementsByClassName("wrapper")).length * 30);
      }
    );
  }

  addGallery() {
    // console.log("addGallery");
    Array.from(document.getElementsByClassName("wrapper")).forEach(
      (item, index) => {
        item.classList.add("out");
        item.classList.remove("bounceOutDown");
        item.addEventListener(
          "animationend",
          this.cleanAfterArrival.bind(this),
          { once: true }
        );
        setTimeout(() => {
          item.classList.add("bounceInDown");
        }, (Array.from(document.getElementsByClassName("wrapper")).length - index) * 80);
      }
    );
  }

  addP5Logo(doc) {
    // console.log("addP5Logo");
    const _p5Logo = document.querySelector(".p5Logo");
    if (_p5Logo) {
      _p5Logo.parentNode.removeChild(_p5Logo);
    }
    const p5Logo = document.createElement("div");
    p5Logo.classList.add("p5Logo");
    p5Logo.innerHTML = `<img src="/p5.svg" alt="p5.js logo" />`;
    doc.body.appendChild(p5Logo);
  }

  addCodeIcon(doc) {
    const _codeIcon = document.querySelector(".codeIcon");
    if (_codeIcon) {
      _codeIcon.parentNode.removeChild(_codeIcon);
    }
    setTimeout(() => {
      const codeIcon = document.createElement("div");
      codeIcon.classList.add("material-symbols-outlined", "codeIcon");
      codeIcon.innerHTML = `code`;
      doc.appendChild(codeIcon);
      codeIcon.addEventListener("click", (e) => {
        console.log(doc.querySelector(".tabLayer"));
        doc.querySelector(".tabLayer").classList.toggle("active");
      });
    }, 100);
  }
}
