import { EditorView, themeClass, Decoration, ViewPlugin, runScopeHandlers } from '@codemirror/view';
import { StateEffect, StateField, EditorSelection, Facet, combineConfig, CharCategory, Prec } from '@codemirror/state';
import { showPanel, getPanel, panels } from '@codemirror/panel';
import { RangeSetBuilder } from '@codemirror/rangeset';
import elt from 'crelt';
import { findClusterBreak } from '@codemirror/text';

const basicNormalize = typeof String.prototype.normalize == "function" ? x => x.normalize("NFKD") : x => x;
/// A search cursor provides an iterator over text matches in a
/// document.
class SearchCursor {
    /// Create a text cursor. The query is the search string, `from` to
    /// `to` provides the region to search.
    ///
    /// When `normalize` is given, it will be called, on both the query
    /// string and the content it is matched against, before comparing.
    /// You can, for example, create a case-insensitive search by
    /// passing `s => s.toLowerCase()`.
    ///
    /// Text is always normalized with
    /// [`.normalize("NFKD")`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize)
    /// (when supported).
    constructor(text, query, from = 0, to = text.length, normalize) {
        /// The current match (only holds a meaningful value after
        /// [`next`](#search.SearchCursor.next) has been called and when
        /// `done` is false).
        this.value = { from: 0, to: 0 };
        /// Whether the end of the iterated region has been reached.
        this.done = false;
        this.matches = [];
        this.buffer = "";
        this.bufferPos = 0;
        this.iter = text.iterRange(from, to);
        this.bufferStart = from;
        this.normalize = normalize ? x => normalize(basicNormalize(x)) : basicNormalize;
        this.query = this.normalize(query);
    }
    peek() {
        if (this.bufferPos == this.buffer.length) {
            this.bufferStart += this.buffer.length;
            this.iter.next();
            if (this.iter.done)
                return -1;
            this.bufferPos = 0;
            this.buffer = this.iter.value;
        }
        return this.buffer.charCodeAt(this.bufferPos);
    }
    /// Look for the next match. Updates the iterator's
    /// [`value`](#search.SearchCursor.value) and
    /// [`done`](#search.SearchCursor.done) properties. Should be called
    /// at least once before using the cursor.
    next() {
        for (;;) {
            let next = this.peek();
            if (next < 0) {
                this.done = true;
                return this;
            }
            let str = String.fromCharCode(next), start = this.bufferStart + this.bufferPos;
            this.bufferPos++;
            for (;;) {
                let peek = this.peek();
                if (peek < 0xDC00 || peek >= 0xE000)
                    break;
                this.bufferPos++;
                str += String.fromCharCode(peek);
            }
            let norm = this.normalize(str);
            for (let i = 0, pos = start;; i++) {
                let code = norm.charCodeAt(i);
                let match = this.match(code, pos);
                if (match) {
                    this.value = match;
                    return this;
                }
                if (i == norm.length - 1)
                    break;
                if (pos == start && i < str.length && str.charCodeAt(i) == code)
                    pos++;
            }
        }
    }
    match(code, pos) {
        let match = null;
        for (let i = 0; i < this.matches.length; i += 2) {
            let index = this.matches[i], keep = false;
            if (this.query.charCodeAt(index) == code) {
                if (index == this.query.length - 1) {
                    match = { from: this.matches[i + 1], to: pos + 1 };
                }
                else {
                    this.matches[i]++;
                    keep = true;
                }
            }
            if (!keep) {
                this.matches.splice(i, 2);
                i -= 2;
            }
        }
        if (this.query.charCodeAt(0) == code) {
            if (this.query.length == 1)
                match = { from: pos, to: pos + 1 };
            else
                this.matches.push(1, pos);
        }
        return match;
    }
}

function createLineDialog(view) {
    let dom = document.createElement("form");
    dom.innerHTML = `<label>${view.state.phrase("Go to line:")} <input class=${themeClass("textfield")} name=line></label>
<button class=${themeClass("button")} type=submit>${view.state.phrase("go")}</button>`;
    let input = dom.querySelector("input");
    function go() {
        let match = /^([+-])?(\d+)?(:\d+)?(%)?$/.exec(input.value);
        if (!match)
            return;
        let { state } = view, startLine = state.doc.lineAt(state.selection.main.head);
        let [, sign, ln, cl, percent] = match;
        let col = cl ? +cl.slice(1) : 0;
        let line = ln ? +ln : startLine.number;
        if (ln && percent) {
            let pc = line / 100;
            if (sign)
                pc = pc * (sign == "-" ? -1 : 1) + (startLine.number / state.doc.lines);
            line = Math.round(state.doc.lines * pc);
        }
        else if (ln && sign) {
            line = line * (sign == "-" ? -1 : 1) + startLine.number;
        }
        let docLine = state.doc.line(Math.max(1, Math.min(state.doc.lines, line)));
        view.dispatch({
            effects: dialogEffect.of(false),
            selection: EditorSelection.cursor(docLine.from + Math.max(0, Math.min(col, docLine.length))),
            scrollIntoView: true
        });
        view.focus();
    }
    dom.addEventListener("keydown", event => {
        if (event.keyCode == 27) { // Escape
            event.preventDefault();
            view.dispatch({ effects: dialogEffect.of(false) });
            view.focus();
        }
        else if (event.keyCode == 13) { // Enter
            event.preventDefault();
            go();
        }
    });
    dom.addEventListener("submit", go);
    return { dom, style: "gotoLine", pos: -10 };
}
const dialogEffect = StateEffect.define();
const dialogField = StateField.define({
    create() { return true; },
    update(value, tr) {
        for (let e of tr.effects)
            if (e.is(dialogEffect))
                value = e.value;
        return value;
    },
    provide: f => showPanel.computeN([f], s => s.field(f) ? [createLineDialog] : [])
});
/// Command that shows a dialog asking the user for a line number, and
/// when a valid position is provided, moves the cursor to that line.
///
/// Supports line numbers, relative line offsets prefixed with `+` or
/// `-`, document percentages suffixed with `%`, and an optional
/// column position by adding `:` and a second number after the line
/// number.
///
/// The dialog can be styled with the `panel.gotoLine` theme
/// selector.
const gotoLine = view => {
    let panel = getPanel(view, createLineDialog);
    if (!panel) {
        view.dispatch({
            reconfigure: view.state.field(dialogField, false) == null ? { append: [panels(), dialogField, baseTheme] } : undefined,
            effects: dialogEffect.of(true)
        });
        panel = getPanel(view, createLineDialog);
    }
    if (panel)
        panel.dom.querySelector("input").focus();
    return true;
};
const baseTheme = EditorView.baseTheme({
    "$panel.gotoLine": {
        padding: "2px 6px 4px",
        "& label": { fontSize: "80%" }
    }
});

const defaultHighlightOptions = {
    highlightWordAroundCursor: false,
    minSelectionLength: 1,
    maxMatches: 100
};
const highlightConfig = Facet.define({
    combine(options) {
        return combineConfig(options, defaultHighlightOptions, {
            highlightWordAroundCursor: (a, b) => a || b,
            minSelectionLength: Math.min,
            maxMatches: Math.min
        });
    }
});
/// This extension highlights text that matches the selection. It uses
/// the `$selectionMatch` theme class for the highlighting. When
/// `highlightWordAroundCursor` is enabled, the word at the cursor
/// itself will be highlighted with `selectionMatch.main`.
function highlightSelectionMatches(options) {
    let ext = [defaultTheme, matchHighlighter];
    if (options)
        ext.push(highlightConfig.of(options));
    return ext;
}
function wordAt(doc, pos, check) {
    let line = doc.lineAt(pos);
    let from = pos - line.from, to = pos - line.from;
    while (from > 0) {
        let prev = findClusterBreak(line.text, from, false);
        if (check(line.text.slice(prev, from)) != CharCategory.Word)
            break;
        from = prev;
    }
    while (to < line.length) {
        let next = findClusterBreak(line.text, to);
        if (check(line.text.slice(to, next)) != CharCategory.Word)
            break;
        to = next;
    }
    return from == to ? null : line.text.slice(from, to);
}
const matchDeco = Decoration.mark({ class: themeClass("selectionMatch") });
const mainMatchDeco = Decoration.mark({ class: themeClass("selectionMatch.main") });
const matchHighlighter = ViewPlugin.fromClass(class {
    constructor(view) {
        this.decorations = this.getDeco(view);
    }
    update(update) {
        if (update.selectionSet || update.docChanged || update.viewportChanged)
            this.decorations = this.getDeco(update.view);
    }
    getDeco(view) {
        let conf = view.state.facet(highlightConfig);
        let { state } = view, sel = state.selection;
        if (sel.ranges.length > 1)
            return Decoration.none;
        let range = sel.main, query, check = null;
        if (range.empty) {
            if (!conf.highlightWordAroundCursor)
                return Decoration.none;
            check = state.charCategorizer(range.head);
            query = wordAt(state.doc, range.head, check);
            if (!query)
                return Decoration.none;
        }
        else {
            let len = range.to - range.from;
            if (len < conf.minSelectionLength || len > 200)
                return Decoration.none;
            query = state.sliceDoc(range.from, range.to).trim();
            if (!query)
                return Decoration.none;
        }
        let deco = [];
        for (let part of view.visibleRanges) {
            let cursor = new SearchCursor(state.doc, query, part.from, part.to);
            while (!cursor.next().done) {
                let { from, to } = cursor.value;
                if (!check || ((from == 0 || check(state.sliceDoc(from - 1, from)) != CharCategory.Word) &&
                    (to == state.doc.length || check(state.sliceDoc(to, to + 1)) != CharCategory.Word))) {
                    if (check && from <= range.from && to >= range.to)
                        deco.push(mainMatchDeco.range(from, to));
                    else if (from >= range.to || to <= range.from)
                        deco.push(matchDeco.range(from, to));
                    if (deco.length > conf.maxMatches)
                        return Decoration.none;
                }
            }
        }
        return Decoration.set(deco);
    }
}, {
    decorations: v => v.decorations
});
const defaultTheme = EditorView.baseTheme({
    "$selectionMatch": { backgroundColor: "#99ff7780" },
    "$searchMatch $selectionMatch": { backgroundColor: "transparent" }
});

class Query {
    constructor(search, replace, caseInsensitive) {
        this.search = search;
        this.replace = replace;
        this.caseInsensitive = caseInsensitive;
    }
    eq(other) {
        return this.search == other.search && this.replace == other.replace && this.caseInsensitive == other.caseInsensitive;
    }
    cursor(doc, from = 0, to = doc.length) {
        return new SearchCursor(doc, this.search, from, to, this.caseInsensitive ? x => x.toLowerCase() : undefined);
    }
    get valid() { return !!this.search; }
}
const setQuery = StateEffect.define();
const togglePanel = StateEffect.define();
const searchState = StateField.define({
    create() {
        return new SearchState(new Query("", "", false), []);
    },
    update(value, tr) {
        for (let effect of tr.effects) {
            if (effect.is(setQuery))
                value = new SearchState(effect.value, value.panel);
            else if (effect.is(togglePanel))
                value = new SearchState(value.query, effect.value ? [createSearchPanel] : []);
        }
        return value;
    },
    provide: f => showPanel.computeN([f], s => s.field(f).panel)
});
class SearchState {
    constructor(query, panel) {
        this.query = query;
        this.panel = panel;
    }
}
const matchMark = Decoration.mark({ class: themeClass("searchMatch") }), selectedMatchMark = Decoration.mark({ class: themeClass("searchMatch.selected") });
const searchHighlighter = ViewPlugin.fromClass(class {
    constructor(view) {
        this.view = view;
        this.decorations = this.highlight(view.state.field(searchState));
    }
    update(update) {
        let state = update.state.field(searchState);
        if (state != update.startState.field(searchState) || update.docChanged || update.selectionSet)
            this.decorations = this.highlight(state);
    }
    highlight({ query, panel }) {
        if (!panel.length || !query.valid)
            return Decoration.none;
        let state = this.view.state, viewport = this.view.viewport;
        let cursor = query.cursor(state.doc, Math.max(0, viewport.from - query.search.length), Math.min(viewport.to + query.search.length, state.doc.length));
        let builder = new RangeSetBuilder();
        while (!cursor.next().done) {
            let { from, to } = cursor.value;
            let selected = state.selection.ranges.some(r => r.from == from && r.to == to);
            builder.add(from, to, selected ? selectedMatchMark : matchMark);
        }
        return builder.finish();
    }
}, {
    decorations: v => v.decorations
});
function searchCommand(f) {
    return view => {
        let state = view.state.field(searchState, false);
        return state && state.query.valid ? f(view, state) : openSearchPanel(view);
    };
}
function findNextMatch(doc, from, query) {
    let cursor = query.cursor(doc, from).next();
    if (cursor.done) {
        cursor = query.cursor(doc, 0, from + query.search.length - 1).next();
        if (cursor.done)
            return null;
    }
    return cursor.value;
}
/// Open the search panel if it isn't already open, and move the
/// selection to the first match after the current main selection.
/// Will wrap around to the start of the document when it reaches the
/// end.
const findNext = searchCommand((view, state) => {
    let { from, to } = view.state.selection.main;
    let next = findNextMatch(view.state.doc, view.state.selection.main.from + 1, state.query);
    if (!next || next.from == from && next.to == to)
        return false;
    view.dispatch({ selection: { anchor: next.from, head: next.to }, scrollIntoView: true });
    maybeAnnounceMatch(view);
    return true;
});
const FindPrevChunkSize = 10000;
// Searching in reverse is, rather than implementing inverted search
// cursor, done by scanning chunk after chunk forward.
function findPrevInRange(query, doc, from, to) {
    for (let pos = to;;) {
        let start = Math.max(from, pos - FindPrevChunkSize - query.search.length);
        let cursor = query.cursor(doc, start, pos), range = null;
        while (!cursor.next().done)
            range = cursor.value;
        if (range)
            return range;
        if (start == from)
            return null;
        pos -= FindPrevChunkSize;
    }
}
/// Move the selection to the previous instance of the search query,
/// before the current main selection. Will wrap past the start
/// of the document to start searching at the end again.
const findPrevious = searchCommand((view, { query }) => {
    let { state } = view;
    let range = findPrevInRange(query, state.doc, 0, state.selection.main.to - 1) ||
        findPrevInRange(query, state.doc, state.selection.main.from + 1, state.doc.length);
    if (!range)
        return false;
    view.dispatch({ selection: { anchor: range.from, head: range.to }, scrollIntoView: true });
    maybeAnnounceMatch(view);
    return true;
});
/// Select all instances of the search query.
const selectMatches = searchCommand((view, { query }) => {
    let cursor = query.cursor(view.state.doc), ranges = [];
    while (!cursor.next().done)
        ranges.push(EditorSelection.range(cursor.value.from, cursor.value.to));
    if (!ranges.length)
        return false;
    view.dispatch({ selection: EditorSelection.create(ranges) });
    return true;
});
/// Select all instances of the currently selected text.
const selectSelectionMatches = ({ state, dispatch }) => {
    let sel = state.selection;
    if (sel.ranges.length > 1 || sel.main.empty)
        return false;
    let { from, to } = sel.main;
    let ranges = [], main = 0;
    for (let cur = new SearchCursor(state.doc, state.sliceDoc(from, to)); !cur.next().done;) {
        if (ranges.length > 1000)
            return false;
        if (cur.value.from == from)
            main = ranges.length;
        ranges.push(EditorSelection.range(cur.value.from, cur.value.to));
    }
    dispatch(state.update({ selection: EditorSelection.create(ranges, main) }));
    return true;
};
/// Replace the current match of the search query.
const replaceNext = searchCommand((view, { query }) => {
    let { state } = view, next = findNextMatch(state.doc, state.selection.main.from, query);
    if (!next)
        return false;
    let { from, to } = state.selection.main, changes = [], selection;
    if (next.from == from && next.to == to) {
        changes.push({ from: next.from, to: next.to, insert: query.replace });
        next = findNextMatch(state.doc, next.to, query);
    }
    if (next) {
        let off = changes.length == 0 || changes[0].from >= next.to ? 0 : next.to - next.from - query.replace.length;
        selection = { anchor: next.from - off, head: next.to - off };
    }
    view.dispatch({ changes, selection, scrollIntoView: !!selection });
    if (next)
        maybeAnnounceMatch(view);
    return true;
});
/// Replace all instances of the search query with the given
/// replacement.
const replaceAll = searchCommand((view, { query }) => {
    let cursor = query.cursor(view.state.doc), changes = [];
    while (!cursor.next().done) {
        let { from, to } = cursor.value;
        changes.push({ from, to, insert: query.replace });
    }
    if (!changes.length)
        return false;
    view.dispatch({ changes });
    return true;
});
function createSearchPanel(view) {
    let { query } = view.state.field(searchState);
    return {
        dom: buildPanel({
            view,
            query,
            updateQuery(q) {
                if (!query.eq(q)) {
                    query = q;
                    view.dispatch({ effects: setQuery.of(query) });
                }
            }
        }),
        mount() {
            this.dom.querySelector("[name=search]").select();
        },
        pos: 80,
        style: "search"
    };
}
/// Make sure the search panel is open and focused.
const openSearchPanel = view => {
    let state = view.state.field(searchState, false);
    if (state && state.panel.length) {
        let panel = getPanel(view, createSearchPanel);
        if (!panel)
            return false;
        panel.dom.querySelector("[name=search]").focus();
    }
    else {
        view.dispatch({ effects: togglePanel.of(true),
            reconfigure: state ? undefined : { append: searchExtensions } });
    }
    return true;
};
/// Close the search panel.
const closeSearchPanel = view => {
    let state = view.state.field(searchState, false);
    if (!state || !state.panel.length)
        return false;
    let panel = getPanel(view, createSearchPanel);
    if (panel && panel.dom.contains(view.root.activeElement))
        view.focus();
    view.dispatch({ effects: togglePanel.of(false) });
    return true;
};
/// Default search-related key bindings.
///
///  - Mod-f: [`openSearchPanel`](#search.openSearchPanel)
///  - F3, Mod-g: [`findNext`](#search.findNext)
///  - Shift-F3, Shift-Mod-g: [`findPrevious`](#search.findPrevious)
///  - Alt-g: [`gotoLine`](#search.gotoLine)
const searchKeymap = [
    { key: "Mod-f", run: openSearchPanel, scope: "editor search-panel" },
    { key: "F3", run: findNext, shift: findPrevious, scope: "editor search-panel" },
    { key: "Mod-g", run: findNext, shift: findPrevious, scope: "editor search-panel" },
    { key: "Escape", run: closeSearchPanel, scope: "editor search-panel" },
    { key: "Mod-Shift-l", run: selectSelectionMatches },
    { key: "Alt-g", run: gotoLine }
];
function buildPanel(conf) {
    function p(phrase) { return conf.view.state.phrase(phrase); }
    let searchField = elt("input", {
        value: conf.query.search,
        placeholder: p("Find"),
        "aria-label": p("Find"),
        class: themeClass("textfield"),
        name: "search",
        onchange: update,
        onkeyup: update
    });
    let replaceField = elt("input", {
        value: conf.query.replace,
        placeholder: p("Replace"),
        "aria-label": p("Replace"),
        class: themeClass("textfield"),
        name: "replace",
        onchange: update,
        onkeyup: update
    });
    let caseField = elt("input", {
        type: "checkbox",
        name: "case",
        checked: !conf.query.caseInsensitive,
        onchange: update
    });
    function update() {
        conf.updateQuery(new Query(searchField.value, replaceField.value, !caseField.checked));
    }
    function keydown(e) {
        if (runScopeHandlers(conf.view, e, "search-panel")) {
            e.preventDefault();
        }
        else if (e.keyCode == 13 && e.target == searchField) {
            e.preventDefault();
            (e.shiftKey ? findPrevious : findNext)(conf.view);
        }
        else if (e.keyCode == 13 && e.target == replaceField) {
            e.preventDefault();
            replaceNext(conf.view);
        }
    }
    function button(name, onclick, content) {
        return elt("button", { class: themeClass("button"), name, onclick }, content);
    }
    let panel = elt("div", { onkeydown: keydown }, [
        searchField,
        button("next", () => findNext(conf.view), [p("next")]),
        button("prev", () => findPrevious(conf.view), [p("previous")]),
        button("select", () => selectMatches(conf.view), [p("all")]),
        elt("label", null, [caseField, "match case"]),
        elt("br"),
        replaceField,
        button("replace", () => replaceNext(conf.view), [p("replace")]),
        button("replaceAll", () => replaceAll(conf.view), [p("replace all")]),
        elt("button", { name: "close", onclick: () => closeSearchPanel(conf.view), "aria-label": p("close") }, ["×"]),
        elt("div", { style: "position: absolute; top: -10000px", "aria-live": "polite" })
    ]);
    return panel;
}
const AnnounceMargin = 30;
const Break = /[\s\.,:;?!]/;
// FIXME this is a kludge
function maybeAnnounceMatch(view) {
    let { from, to } = view.state.selection.main;
    let lineStart = view.state.doc.lineAt(from).from, lineEnd = view.state.doc.lineAt(to).to;
    let start = Math.max(lineStart, from - AnnounceMargin), end = Math.min(lineEnd, to + AnnounceMargin);
    let text = view.state.sliceDoc(start, end);
    if (start != lineStart) {
        for (let i = 0; i < AnnounceMargin; i++)
            if (!Break.test(text[i + 1]) && Break.test(text[i])) {
                text = text.slice(i);
                break;
            }
    }
    if (end != lineEnd) {
        for (let i = text.length - 1; i > text.length - AnnounceMargin; i--)
            if (!Break.test(text[i - 1]) && Break.test(text[i])) {
                text = text.slice(0, i);
                break;
            }
    }
    let panel = getPanel(view, createSearchPanel);
    if (!panel || !panel.dom.contains(view.root.activeElement))
        return;
    let live = panel.dom.querySelector("div[aria-live]");
    live.textContent = view.state.phrase("current match") + ". " + text;
}
const baseTheme$1 = EditorView.baseTheme({
    "$panel.search": {
        padding: "2px 6px 4px",
        position: "relative",
        "& [name=close]": {
            position: "absolute",
            top: "0",
            right: "4px",
            backgroundColor: "inherit",
            border: "none",
            font: "inherit",
            padding: 0,
            margin: 0
        },
        "& input, & button": {
            margin: ".2em .5em .2em 0"
        },
        "& label": {
            fontSize: "80%"
        }
    },
    "$$light $searchMatch": { backgroundColor: "#ffff0054" },
    "$$dark $searchMatch": { backgroundColor: "#00ffff8a" },
    "$$light $searchMatch.selected": { backgroundColor: "#ff6a0054" },
    "$$dark $searchMatch.selected": { backgroundColor: "#ff00ff8a" }
});
const searchExtensions = [
    searchState,
    Prec.override(searchHighlighter),
    panels(),
    baseTheme$1
];

export { SearchCursor, closeSearchPanel, findNext, findPrevious, gotoLine, highlightSelectionMatches, openSearchPanel, replaceAll, replaceNext, searchKeymap, selectMatches, selectSelectionMatches };
